<template>
    <div>
        <el-form class="bandCreateEdit" size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"
            v-if="show">
            <el-form-item label="编号:" v-show="action == 'edit'">
                <el-row>{{ ruleForm.bNumber }}</el-row>
            </el-form-item>
            <el-form-item label="品牌名称:" prop="bName">
                <el-input style="width: 400px" v-model="ruleForm.bName"></el-input>
            </el-form-item>
            <el-form-item label="所属企业:" prop="eId">
                <el-select style="width: 400px" v-model="ruleForm.eId" placeholder="请选择所属企业" clearable filterable>
                    <el-option v-for="item in enterpriseDataList" :key="item.eId" :label="item.name" :value="item.eId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="企业空间地址:" prop="address">
                <el-input style="width: 400px" v-model="ruleForm.address"></el-input>
            </el-form-item>
            <el-form-item label="品牌LOGO:" prop="brandLogo">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandLogoChange">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为900x360px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌主图:" prop="brandMainSrc">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandMainSrcChange">
                    <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为1920*360px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌副图:" prop="brandSubSrc">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandSubSrcChange">
                    <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为440*265px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌介绍:">
                <el-upload class="avatar-uploader" action="" :limit="5" :file-list="ruleForm.brandIntroduceSrc"
                    list-type="picture-card" :auto-upload="false" :on-change="handleBrandIntroduceSrcChange"
                    :on-exceed="handleExceed" :on-remove="handleBrandIntroduceRemove">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为1920*1080px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌视频:">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandVideoSrcChange">
                    <video class="avatar" style="width: 100%; height: 100%; object-fit: cover" v-if="videoUrl" controls
                        :src="videoUrl"></video>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="upload-desc">
                    <span class="el-upload__text">上传视频尺寸要求宽高>=1920*1080px</span>
                    <p class="el-upload__text">格式为：AVI，WMV，MPEG，3GP等主流视频格式</p>
                </div>
            </el-form-item>
            <el-form-item style="margin-top: 35px">
                <el-button style="width: 100px" type="primary" @click="handleSave">确认</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getEnterpriseData, bandManageAdd, bandManageEdit } from '@/common/api/category/bandManage.js';
import jsonTool from '@/utils/jsonTool.js';
export default {
    name: 'bandCreateEdit',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        action: {
            type: String,
            default: ''
        },
        bandEditData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        var validateName = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('品牌名称不能为空'))
            } else {
                callback();
            }
        }
        var validateEnterpriseId = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('所属企业不能为空'))
            } else {
                callback();
            }
        }
        return {
            videoUrl:'',
            deleteImg:[],
            imageUrl:'',
            imageUrl1:'',
            imageUrl2:'',
            ruleForm: {
                // id
                bId: '',
                // 编号
                bNumber: '',
                // 名称
                bName: '',
                // 所属企业id
                eId: '',
                //企业空间地址
                address:'',
                // 所属企业名称
                enterpriseName: '',
                //品牌介绍
                brandIntroduceSrc:[],
                //品牌视频
                brandVideoSrc:[],
                //品牌主图
                brandMainSrc:[],
                //品牌副图
                brandSubSrc:[],
                //品牌logo
                brandLogo:[],
            },
            // 所属企业列表数据
            enterpriseDataList: [],
            rules: {
                bName: [{ required: true,validator: validateName, trigger: 'blur' }],
                eId: [{ required: true,validator: validateEnterpriseId, trigger: 'change' }]
            }
        }
    },
    mounted(){
        this.queryEnterpriseDataList();
        if(this.action == 'edit') {
            this.ruleForm = this.bandEditData;
        }
    },
    methods: {
        // 处理品牌视频相关事件
        handleBrandVideoSrcChange(file) {
            const isVideo =
                file.raw.type === 'video/mp4' ||
                file.raw.type === 'video/mpeg' ||
                file.raw.type === 'video/flv' ||
                file.raw.type === 'video/avi' ||
                file.raw.type === 'video/wmv' ||
                file.raw.type === 'video/rmvb' ||
                file.raw.type === 'video/3gp' ||
                file.raw.type === 'video/mov';
            const isLt500M = file.raw.size / 1024 / 1024 < 500;
            if (!isVideo) {
                this.$message.warning('请上传正确格式的视频！');
                return false;
            } else if (!isLt500M) {
                this.$message.warning('上传视频文件大小不能超过 500MB!');
                return false;
            }
            new Promise((resolve, reject) => {
                let _URL = window.URL || window.webkitURL;
                let videoElement = document.createElement('video');
                videoElement.addEventListener('loadedmetadata', function () {
                    // 判断上传视频的宽高是否为16:9分辨率的
                    // 位操作符 NOT
                    let valid =
                        (~~(parseInt(videoElement.videoWidth) / 16) * 9 === parseInt(videoElement.videoHeight) &&
                            videoElement.videoWidth === 3840) ||
                        videoElement.videoWidth === 2560 ||
                        videoElement.videoWidth === 1980 ||
                        videoElement.videoHeight === 2160 ||
                        videoElement.videoHeight === 1440 ||
                        videoElement.videoHeight === 1080;
                    valid ? resolve() : reject();
                });
                videoElement.src = _URL.createObjectURL(file.raw);
            }).then(
                () => {
                    this.ruleForm.brandVideoSrc.push(file);
                    this.videoUrl = file.url;
                    return file;
                },
                () => {
                    this.$message.error('请上传正确比例的视频');
                    return false;
                }
            );
        },
        // 品牌介绍图片删除
        handleBrandIntroduceRemove(file, fileList) {
            this.deleteImg.push(file.url);
            this.ruleForm.brandIntroduceSrc = fileList;
            // let params = {
            //     BrandID: this.brandManageEditData.BrandID,
            //     PicturePath: file.url
            // };
            // deletePicture(params)
            //     .then(res => {
            //         let { code, msg } = res;
            //         if (code === 200) {
            //             this.$message({
            //                 type: 'success',
            //                 message: `${msg}`,
            //                 center: true
            //             });
            //             this.ruleForm.brandIntroduceSrc = fileList;
            //         }
            //     })
            //     .catch(err => {
            //         console.log(err);
            //     });
        },
        // 上传图片公用函数
        handleExceed(files, fileList) {
            this.$message.error(
                `当前限制选择5个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                    files.length + fileList.length
                } 个文件`
            );
        },
        // 处理品牌l副图相关事件
        handleBrandSubSrcChange(file) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' ||  file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 440;
                let height = 265;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.ruleForm.brandSubSrc = [];
                    this.ruleForm.brandSubSrc.push(file);
                    this.imageUrl2 = file.url;
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 440*265px !');
                    return false;
                }
            );
        },
        // 处理品牌介绍相关事件
        handleBrandIntroduceSrcChange(file, fileList) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' ||  file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                fileList.pop();
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                fileList.pop();
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 1920;
                let height = 1080;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height <= height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.ruleForm.brandIntroduceSrc.push(file);
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸尺寸为1920*1080px');
                    fileList.pop();
                    return Promise.reject();
                }
            );
        },
        handleBrandMainSrcChange(file) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' ||  file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 1920;
                let height = 360;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.imageUrl1 = file.url;
                    this.ruleForm.brandMainSrc.push(file);
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 1920*1080px !');
                    return false;
                }
            );
        },
        // 处理品牌logo相关事件
        handleBrandLogoChange(file) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' ||  file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 900;
                let height = 360;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.imageUrl = file.url;
                    this.ruleForm.brandLogo[0] = file;
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 900*360px !');
                    return Promise.reject();
                }
            );
        },
        // 获取所属企业数据列表
        queryEnterpriseDataList(){
            getEnterpriseData().then(res => {
                if(res.code === 200){
                    this.enterpriseDataList = jsonTool(res.result);
                }
            }).catch(err => {
                // console.log(err);
            })
        },
        // 处理保存按钮
        handleSave() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid){
                    let params = new FormData();
                    params.append('b_name',this.ruleForm.bName);
                    params.append('e_id',this.ruleForm.eId);
                    params.append('spatial_address',this.ruleForm.address);
                    if (this.ruleForm.brandLogo.length) {
                        for (var i = 0; i < this.ruleForm.brandLogo.length; i++) {
                            if (this.ruleForm.brandLogo[i].raw) {
                                params.append('Logo', this.ruleForm.brandLogo[i].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandMainSrc.length) {
                        for (var j = 0; j < this.ruleForm.brandMainSrc.length; j++) {
                            if (this.ruleForm.brandMainSrc[j].raw) {
                                params.append('MasterMap', this.ruleForm.brandMainSrc[j].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandSubSrc.length) {
                        for (var z = 0; z < this.ruleForm.brandSubSrc.length; z++) {
                            if (this.ruleForm.brandSubSrc[z].raw) {
                                params.append('AuxiliaryChart', this.ruleForm.brandSubSrc[z].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandIntroduceSrc.length) {
                        for (var m = 0; m < this.ruleForm.brandIntroduceSrc.length; m++) {
                            if (this.ruleForm.brandIntroduceSrc[m].raw) {
                                params.append('IntroducePicture[]', this.ruleForm.brandIntroduceSrc[m].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandVideoSrc.length) {
                        for (var n = 0; n < this.ruleForm.brandVideoSrc.length; n++) {
                            if (this.ruleForm.brandVideoSrc[n].raw) {
                                params.append('Video', this.ruleForm.brandVideoSrc[n].raw);
                            }
                        }
                    }
                    for (let [a, b] of params.entries()) {
                        // console.log(a + '=>' + b);
                    }
                    if (this.action == 'edit'){
                        params.append('b_id',this.ruleForm.bId);
                        if (this.deleteImg.length){
                            for (var b=0;b<this.deleteImg.length;b++){
                                params.append('DelIntroducePicture[]',this.deleteImg[b]);
                            }
                        }
                    }
                    let submitFunc = this.action == 'edit' ? bandManageEdit : bandManageAdd;
                    submitFunc(params).then(res => {
                        if(res.code === 200){
                            let msg = this.action == 'edit' ? '编辑成功' : '添加成功';
                            this.$message.success(msg);
                            this.ruleForm = {
                                // id
                                bId: '',
                                // 编号
                                bNumber: '',
                                // 名称
                                bName: '',
                                // 所属企业id
                                eId: '',
                                //企业空间地址
                                address:'',
                                // 所属企业名称
                                enterpriseName: '',
                                //品牌介绍
                                brandIntroduceSrc:[],
                                //品牌视频
                                brandVideoSrc:[],
                                //品牌主图
                                brandMainSrc:[],
                                //品牌副图
                                brandSubSrc:[],
                                //品牌logo
                                brandLogo:[],
                            };
                            this.$emit('close', false);
                        }
                    }).catch(err => {
                        // console.log(err);
                    })
                }
            })
        }
    },
    created(){
        if (this.bandEditData){
            let newVal = this.bandEditData;
            this.$nextTick(()=>{
                let tt = [];
                if (newVal.IntroducePicture && newVal.IntroducePicture.length){
                    for (var int=0;int<newVal.IntroducePicture.length;int++){
                        tt.push({url:newVal.IntroducePicture[int]});
                    }
                }
                this.ruleForm = {
                    // id
                    bId: newVal.bId ? newVal.bId : '',
                    // 编号
                    bNumber: newVal.bNumber ? newVal.bNumber : '',
                    // 名称
                    bName: newVal.bName ? newVal.bName : '',
                    // 所属企业id
                    eId: newVal.eId ? newVal.eId : '',
                    //企业空间地址
                    address: newVal.spatialAddress ? newVal.spatialAddress : '',
                    // 所属企业名称
                    enterpriseName: newVal.enterpriseName ? newVal.enterpriseName : '',
                    //品牌介绍
                    brandIntroduceSrc: tt,
                    //品牌视频
                    brandVideoSrc: newVal.Video ? [{url:newVal.Video}] : [],
                    //品牌主图
                    brandMainSrc: newVal.MasterMap ? [{url:newVal.MasterMap}] : [],
                    //品牌副图
                    brandSubSrc: newVal.AuxiliaryChart ? [{url:newVal.AuxiliaryChart}] : [],
                    //品牌logo
                    brandLogo: newVal.Logo ? [{url:newVal.Logo}] : [],
                };
                this.imageUrl = newVal.Logo ? newVal.Logo : '';
                this.imageUrl1 = newVal.MasterMap ? newVal.MasterMap : '';
                this.imageUrl2 = newVal.AuxiliaryChart ? newVal.AuxiliaryChart : '';
                this.videoUrl = newVal.Video ? newVal.Video : '';
            })

        }
    }
};
</script>

<style lang="scss" scoped>
</style>