import $http from '../../../utils/http.js';

/**
 * 品牌管理列表
 * @param data
 */
export function getBandManageList(data) {
    return $http.get('/Brand/index', data, 'loadingDiv');
}

/**
 * 品牌管理列表停用/启用操作
 * @param data
 */
export function bandManageChangeStatus(data) {
    return $http.post('/Brand/enable', data, 'loadingDiv');
}

/**
 * 品牌管理列表删除操作
 * @param data
 */
export function bandManageDeleteAction(data) {
    return $http.post('/Brand/delete', data, 'loadingDiv');
}

/**
 * 所属企业列表数据
 * @param data
 */
 export function getEnterpriseData(data) {
    return $http.post('/Brand/e_info', data, 'loadingDiv');
}

/**
 * 品牌管理列表新建操作
 * @param data
 */
export function bandManageAdd(data) {
    return $http.post('/Brand/add', data, 'bandCreateEdit','multipart/form-data');
}

/**
 * 品牌管理列表编辑操作
 * @param data
 */
export function bandManageEdit(data) {
    return $http.post('/Brand/edit', data, 'bandCreateEdit','multipart/form-data');
}
