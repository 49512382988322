// 品类管理之状态列表
export const statusList = [
    {
        id: '',
        name: '全部'
    },
    {
        id: 0,
        name: '停用'
    },
    {
        id: 1,
        name: '正常'
    }
]
