<template>
    <div style="width: 100%">
        <el-row>
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="名称及编号:">
                        <el-input style="width: 200px" v-model="formInline.searchVal" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select clearable style="width: 200px" v-model="formInline.status" placeholder="全部">
                            <el-option v-for="item in statusArray" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right; margin-right: 0">
                        <el-button style="width: 100px" type="primary" :disabled="!permissionsAction.add"
                            @click="handleCreateEdit('create')">新建</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div style="width: 100%">
                <el-table class="brand-manage-table" :header-cell-style="{ background: '#F2F2F2' }"
                    :data="bandManageDataList" border align="center" style="width: 100%">
                    <el-table-column label="序号" width="50px" type="index" :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column label="编号" width="240" align="center">
                        <template #default="scope">
                            <!--                            <div style="color: #409eff" class="jumpToSeries" @click="jumpToSeries(scope.row)">-->
                            <div style="color: #409eff" class="jumpToSeries">
                                {{ scope.row.bNumber }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌名称" width="180" align="center">
                        <template #default="scope">
                            <div class="jumpToSeries">{{ scope.row.bName }}</div>
                            <!--                            <div class="jumpToSeries" @click="jumpToSeries(scope.row)">{{ scope.row.bName }}</div>-->
                        </template>
                    </el-table-column>
                    <el-table-column prop="bUpdatetime" width="180" label="更新时间" align="center"></el-table-column>
                    <el-table-column prop="bIsEnable" label="状态" align="center">
                        <template #default="scope">
                            <div>{{ statusName(scope.row) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="eNeme" width="220" label="所属企业" align="center"></el-table-column>
                    <el-table-column prop="materialName" label="品牌构建" align="center"></el-table-column>
                    <el-table-column prop="Logo" width="120" label="品牌LOGO" align="center">
                        <template #default="scope">
                            <div v-if="scope.row.Logo">
                                <el-button type="text" @click="previewBrandLogo(scope.$index)">查看</el-button>
                                <el-image-viewer @close="closeViewerLogo(scope.$index)"
                                    v-if="showViewerLogo[scope.$index]" :url-list="[scope.row.Logo]">
                                </el-image-viewer>
                            </div>
                            <el-button type="text" v-else disabled>/</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MasterMap" label="品牌主图" align="center">
                        <template #default="scope">
                            <div v-if="scope.row.MasterMap">
                                <el-button type="text" @click="previewBrandMainFigure(scope.$index)">查看</el-button>
                                <el-image-viewer @close="closeViewerMasterMap(scope.$index)"
                                    v-if="showViewerMasterMap[scope.$index]" :url-list="[scope.row.MasterMap]">
                                </el-image-viewer>
                            </div>
                            <el-button type="text" v-else disabled>/</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="AuxiliaryChart" label="品牌副图" align="center">
                        <template #default="scope">
                            <div v-if="scope.row.AuxiliaryChart">
                                <el-button type="text" @click="previewbrandSubFigure(scope.$index)">查看</el-button>
                                <el-image-viewer @close="closeViewerAuxiliaryChart(scope.$index)"
                                    v-if="showViewerAuxiliaryChart[scope.$index]"
                                    :url-list="[scope.row.AuxiliaryChart]">
                                </el-image-viewer>
                            </div>
                            <el-button type="text" v-else disabled>/</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌介绍(图片)" width="130" align="center">
                        <template #default="scope">
                            <div v-if="scope.row.IntroducePicture && scope.row.IntroducePicture.length > 0">
                                <el-button type="text" @click="previewBrandIntroduceFigure(scope.$index)">查看</el-button>
                                <el-image-viewer @close="closeViewerIntroducePicture(scope.$index)"
                                    v-if="showViewerIntroducePicture[scope.$index]"
                                    :url-list="scope.row.IntroducePicture">
                                </el-image-viewer>
                            </div>
                            <el-button type="text" v-else disabled>/</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌视频" align="center">
                        <template #default="scope">
                            <div v-if="scope.row.Video">
                                <el-button type="text" @click="previewbrandVideo(scope.row)">查看</el-button>
                            </div>
                            <el-button type="text" v-else disabled>/</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="brandCount" label="构件数" align="center"></el-table-column>
                    <el-table-column label="操作" width="180" fixed="right" align="center">
                        <template #default="scope">
                            <el-button :disabled="!permissionsAction.edit" @click="handleCreateEdit('edit', scope.row)"
                                type="text" size="small">编辑</el-button>
                            <el-button :disabled="!permissionsAction.enable" @click="handleChangeStatus(scope.row)"
                                type="text" size="small">{{ parseInt(scope.row.bIsEnable) === 0 ? '启用' : '停用' }}
                            </el-button>
                            <el-button v-if="parseInt(scope.row.bIsEnable) === 0" :disabled="!permissionsAction.del"
                                @click="handleDeleteBtn(scope.row)" type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px" v-if="page.total != 0">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <el-dialog title="品牌视频" width="72%" top="35px" @close="closeVideo" append-to-body v-if="dialogBrandVideoVisible"
            v-model="dialogBrandVideoVisible" center>
            <video class="video" style="width: 100%; height: 100%; object-fit: cover" v-if="Video" controls
                :src="Video"></video>
        </el-dialog>
        <!-- 新建/编辑弹窗 -->
        <el-dialog :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false"
            :title="type == 'create' ? '新建品牌' : '编辑品牌'" v-if="dialogFormVisible" v-model="dialogFormVisible" width="50%"
            center>
            <band-create-edit :show="dialogFormVisible" :action="type" :band-edit-data="bandEditData"
                @close="closeDialog"></band-create-edit>
        </el-dialog>
    </div>
</template>
<script>
import bandCreateEdit from '../component/band-manage/bandCreateEdit.vue';
import jsonTool from '@/utils/jsonTool.js';
import { get as getStorage, set as setStrorage } from '@/utils/storage.js';
import { statusList } from '@/common/js/common-data/status-list.js';
import { getBandManageList, bandManageChangeStatus, bandManageDeleteAction } from '@/common/api/category/bandManage.js';
export default {
    name: 'bandManagePage',
    components: {
        bandCreateEdit
    },
    data() {
        return {
            Video: '',
            dialogBrandVideoVisible: false,
            statusArray: statusList,
            formInline: {
                searchVal: '',
                status: ''
            },
            bandManageDataList: [],
            // 权限操作集合
            permissionsAction: {},
            page: {
                page: getStorage('currentPage', true) || 1,
                pageSize: 10,
                total: 0
            },
            dialogFormVisible: false,
            // 弹窗类型
            type: '',
            // 编辑弹窗回显数据
            bandEditData: {},
            showViewerLogo: [],
            showViewerAuxiliaryChart: [],
            showViewerMasterMap: [],
            showViewerIntroducePicture: [],
        };
    },
    mounted() {
        this.queryBandManageData();
    },
    methods: {
        closeVideo() {
            this.dialogBrandVideoVisible = false;
            this.Video = '';
        },
        closeViewerIntroducePicture(index) {
            this.showViewerIntroducePicture[index] = false;
        },
        closeViewerAuxiliaryChart(index) {
            this.showViewerAuxiliaryChart[index] = false;
        },
        closeViewerMasterMap(index) {
            this.showViewerMasterMap[index] = false;
        },
        closeViewerLogo(index) {
            this.showViewerLogo[index] = false;
        },
        // 查看品牌logo
        previewBrandLogo(index) {
            this.showViewerLogo[index] = true;
        },
        // 查看品牌主图
        previewBrandMainFigure(index) {
            this.showViewerMasterMap[index] = true;
        },
        // 查看品牌副图
        previewbrandSubFigure(index) {
            this.showViewerAuxiliaryChart[index] = true;
        },
        // 查看品牌介绍图
        previewBrandIntroduceFigure(index) {
            this.showViewerIntroducePicture[index] = true;
        },
        // 查看视频
        previewbrandVideo(row) {
            this.Video = row.Video;
            this.dialogBrandVideoVisible = true;
        },
        jumpToSeries(row) {
            this.$emit('bandDetails', row);
        },
        statusName(row) {
            return this.statusArray.find(val => val.id === row.bIsEnable)?.name;
        },
        // 关闭弹窗
        closeDialog(val) {
            this.dialogFormVisible = val;
            this.bandEditData = {};
            this.queryBandManageData();
        },
        // 查询数据列表
        queryBandManageData() {
            let params = {
                page: this.page.page,
                pageSize: this.page.pageSize,
                search: this.formInline.searchVal,
                is_enable: this.formInline.status
            };
            getBandManageList(params)
                .then(res => {
                    let { code, result, Power } = res;
                    if (code === 200) {
                        this.bandManageDataList = jsonTool(result);
                        let { Add: add, Delete: del, Edit: edit, Enable: enable } = Power;
                        this.permissionsAction = {
                            add,
                            del,
                            edit,
                            enable
                        };
                        this.page.total = res.count;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 查询按钮处理
        handleSearch() {
            this.queryBandManageData();
        },
        // 下标序号
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 分页处理
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryBandManageData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            // 分页当前页存储到sessionStorage
            setStrorage('currentPage', this.page.page, true);
            this.queryBandManageData();
        },
        // 处理新建/编辑按钮
        handleCreateEdit(type, row) {
            this.type = type;
            if (this.type == 'edit') {
                let { bId, bNumber, bName, eId, Logo, AuxiliaryChart, IntroducePicture, MasterMap, Video, spatialAddress } = row;
                this.bandEditData = {
                    bId,
                    bNumber,
                    bName,
                    eId,
                    Logo,
                    AuxiliaryChart,
                    IntroducePicture,
                    MasterMap,
                    Video,
                    spatialAddress
                };
            } else {
                this.bandEditData = {};
            }

            this.dialogFormVisible = true;
        },
        // 处理停用/启用按钮
        handleChangeStatus(row) {
            let actionName = row.bIsEnable === 0 ? '启用' : '停用';
            let status = row.bIsEnable == 0 ? 1 : 0;
            let params = {
                b_id: row.bId,
                is_enable: status
            };
            this.$confirm(`是否进行${actionName}操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    bandManageChangeStatus(params)
                        .then(res => {
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!',
                                    center: true
                                });
                                this.queryBandManageData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            let params = {
                b_id: row.bId
            };
            this.$confirm('是否进行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    bandManageDeleteAction(params)
                        .then(res => {
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!',
                                    center: true
                                });
                                this.queryBandManageData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        formatDate(value) {
            if (value) {
                let date = new Date(parseInt(value * 1000));
                let Y = date.getFullYear() + '-';
                let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
                let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
                let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
                let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + h + m + s;
            }
        }
    }
};
</script>
<style scoped lang="scss">
.demo-input-label {
    display: inline-block;
    width: 130px;
}

.jumpToSeries {
    cursor: pointer;
}

.brand-manage-table {
    margin-top: 30px;

    .brand-logo,
    .brand-main-figure,
    .brand-sub-figure {
        :deep .el-image__preview {
            display: none;
        }
    }

    .video {
        display: none;
    }

    .red-color {
        color: red;
    }

    .blue-color {
        color: #409eff;
    }

    .gray-color {
        color: #b2b2b2;
    }
}
</style>