import $http from '../../../utils/http.js';

/**
 * 品牌系列管理列表
 * @param data
 */
export function getseriesManageList(data) {
    return $http.get('/BrandSeries/index', data, 'loadingDiv');
}

/**
 * 品牌系列管理列表停用/启用操作
 * @param data
 */
export function seriesManageChangeStatus(data) {
    return $http.post('/BrandSeries/enable', data, 'loadingDiv');
}

/**
 * 品牌系列管理列表删除操作
 * @param data
 */
export function seriesManageDeleteAction(data) {
    return $http.post('/BrandSeries/delete', data, 'loadingDiv');
}

/**
 * 品牌系列管理列表新建操作
 * @param data
 */
export function seriesManageAdd(data) {
    return $http.post('/BrandSeries/add', data, 'loadingDiv');
}

/**
 * 品牌系列管理列表编辑操作
 * @param data
 */
export function seriesManageEdit(data) {
    return $http.post('/BrandSeries/edit', data, 'loadingDiv');
}