<template>
    <div>
        <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" v-if="show">
            <el-form-item label="编号:" v-show="action == 'edit'">
                <el-row>{{ ruleForm.seriesNumber }}</el-row>
            </el-form-item>
            <el-form-item label="系列名称:" prop="seriesName">
                <el-input style="width: 200px" v-model="ruleForm.seriesName"></el-input>
            </el-form-item>
            <el-form-item label="所属品牌:">
                <el-row>{{ ruleForm.brandName }}</el-row>
            </el-form-item>
            <el-form-item label="所属企业:" v-show="action == 'create'">
                <el-row>{{ ruleForm.eName }}</el-row>
            </el-form-item>
            <el-form-item style="margin-top: 35px">
                <el-button style="width: 100px" type="primary" @click="handleSave">确认</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { seriesManageAdd, seriesManageEdit } from '@/common/api/category/seriesManage.js';
export default {
    name: 'seriesCreateEdit',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        action: {
            type: String,
            default: ''
        },
        seriesEditData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        var validateSeriesName = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('品牌名称不能为空'))
            } else {
                callback();
            }
        }
        return {
            ruleForm: {
                // 品牌id
                brandId: '',
                // 系列id
                seriesId: '',
                // 编号
                seriesNumber: '',
                // 系列名称
                seriesName: '',
                // 所属品牌
                brandName: '',
                // 所属企业
                eName: ''
            },
            rules: {
                seriesName: [{ validator: validateSeriesName, trigger: 'blur' }]
            }
        }
    },
    mounted() {
        this.ruleForm = this.seriesEditData;
    },
    methods: {
        // 处理保存按钮
        handleSave() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let addParams = {
                        series_name: this.ruleForm.seriesName,
                        b_id: this.ruleForm.brandId
                    };
                    let editParams = {
                        series_id: this.ruleForm.seriesId,
                        b_id: this.ruleForm.brandId,
                        series_name: this.ruleForm.seriesName,
                    }
                    let params = this.action == 'edit' ? editParams : addParams;
                    let submitFunc = this.action == 'edit' ? seriesManageEdit : seriesManageAdd;
                    submitFunc(params).then(res => {
                        if (res.code === 200) {
                            this.$emit('close', false);
                        }
                    }).catch(err => {
                        // console.log(err);
                    })
                }
            })
        }
    },
    watch: {
        'seriesEditData': function (newVal) {
            // console.log('newVal: ', newVal);
            this.ruleForm = newVal;
        }
    }
};
</script>

<style lang="scss" scoped>

</style>