<template>
    <div class="seriesManagePage" style="width: 100%">
        <div style="width: 100%">
            <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item>
                    <el-button @click="goBack">返回</el-button>
                </el-form-item>
                <el-form-item label="名称及编号:">
                    <el-input style="width: 200px" v-model="formInline.searchVal" placeholder=" "></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select clearable style="width: 200px" v-model="formInline.status" placeholder="全部">
                        <el-option v-for="item in statusArray" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                </el-form-item>
                <el-form-item style="float: right;margin-right: 0">
                    <el-button style="width: 100px" type="primary"
                        @click="handleCreateEdit('create', seriesManageDataList)">新建</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="width: 100%">
            <el-table :header-cell-style="{background:'#F2F2F2'}" :data="seriesManageDataList" border align="center"
                style="width: 100%">
                <el-table-column label="序号" width="90px" type="index" :index="indexMethod" align="center">
                </el-table-column>
                <el-table-column prop="seriesNumber" label="编号" align="center"></el-table-column>
                <el-table-column prop="seriesName" label="系列名称" align="center"></el-table-column>
                <el-table-column prop="updatetime" label="更新时间" align="center"></el-table-column>
                <el-table-column prop="isEnable" label="状态" align="center">
                    <template v-slot="scope">
                        <div>{{ statusName(scope.row) }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="eName" label="所属企业" align="center"></el-table-column>
                <el-table-column prop="brandName" label="所属品牌" align="center"></el-table-column>
                <el-table-column prop="seriesCount" label="构件数" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template v-slot="scope">
                        <el-button @click="handleCreateEdit('edit', scope.row)" type="text" size="small">编辑</el-button>
                        <el-button v-if="parseInt(scope.row.isEnable) === 0" @click="handleDeleteBtn(scope.row)"
                            type="text" size="small">删除</el-button>
                        <el-button @click="handleChangeStatus(scope.row)" type="text" size="small">{{ scope.row.isEnable
                        === 0 ? '启用' : '停用' }}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: right;margin-top: 25px">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                </el-pagination>
            </div>
        </div>
        <!-- 新建/编辑弹窗 -->
        <el-dialog :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false"
            :title="type == 'create' ? '新建系列' : '编辑系列'" v-model="dialogFormVisible" width="450px" center>
            <series-create-edit :show="dialogFormVisible" :action="type" :series-edit-data="seriesEditData"
                @close="closeDialog"></series-create-edit>
        </el-dialog>
    </div>
</template>
<script>
import seriesCreateEdit from '../component/series-manage/seriesCreateEdit.vue';
import { statusList } from '@/common/js/common-data/status-list.js';
import jsonTool from '@/utils/jsonTool.js';
import { getseriesManageList, seriesManageChangeStatus, seriesManageDeleteAction } from '@/common/api/category/seriesManage.js';
export default {
    name: 'bandManagePage',
    components: {
        seriesCreateEdit
    },
    props: {
        seriesDetailsData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            // 状态列表
            statusArray: statusList,
            formInline: {
                searchVal: '',
                status: ''
            },
            seriesManageDataList: [],
            page: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            dialogFormVisible: false,
            // 弹窗类型
            type: '',
            // 编辑弹窗回显数据
            seriesEditData: {}
        }
    },
    mounted() {
        this.querySeriesManageData();
    },
    methods: {
        // 关闭弹窗
        closeDialog(val) {
            this.dialogFormVisible = val;
            this.querySeriesManageData();
        },
        statusName(row) {
            return this.statusArray.find(val => val.id === row.isEnable)?.name;
        },
        // 处理返回按钮
        goBack() {
            this.$emit('cancelSeries')
        },
        // 查询数据列表
        querySeriesManageData() {
            let params = {
                b_id: this.seriesDetailsData.bId,
                e_id: this.seriesDetailsData.eId,
                page: this.page.page,
                pageSize: this.page.pageSize,
                search: this.formInline.searchVal,
                is_enable: this.formInline.status
            }
            getseriesManageList(params).then(res => {
                if (res.code === 200) {
                    this.seriesManageDataList = jsonTool(res.result);
                    this.page.total = res.count;
                    // console.log(this.seriesManageDataList);
                }
            }).catch(err => {
                // console.log(err);
            })
        },
        // 处理查询按钮
        handleSearch() {
            this.querySeriesManageData();
        },
        // 处理新建/编辑按钮
        handleCreateEdit(type, row) {
            this.type = type;
            if (this.type == 'edit') {
                let { brandId, seriesId, seriesNumber, brandName, seriesName } = row;
                this.seriesEditData = {
                    brandId,
                    seriesId,
                    seriesNumber,
                    brandName,
                    seriesName
                };
            } else {
                this.seriesEditData = {
                    brandId: this.seriesDetailsData.bId,
                    brandName: this.seriesDetailsData.bName,
                    eName: this.seriesDetailsData.eNeme
                };
                // console.log('this.seriesEditData: ', this.seriesEditData);
            }

            this.dialogFormVisible = true;
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            let params = {
                series_id: row.seriesId
            }
            this.$confirm('是否进行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                seriesManageDeleteAction(params).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!',
                            center: true
                        });
                        this.querySeriesManageData();
                    }
                }).catch(err => {
                    // console.log(err);
                })
            }).catch(() => {
                this.$message({
                    type: 'success',
                    message: '已取消操作!',
                    center: true
                })
            });
        },
        // 处理停用/启用按钮
        handleChangeStatus(row) {
            let actionName = row.isEnable === 0 ? "启用" : "停用";
            let status = row.isEnable == 0 ? 1 : 0;
            let params = {
                series_id: row.seriesId,
                is_enable: status
            }
            this.$confirm(`是否进行${actionName}操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                seriesManageChangeStatus(params).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                            center: true
                        })
                        this.querySeriesManageData();
                    }
                }).catch(err => {
                    // console.log(err);
                })

            }).catch(() => {
                this.$message({
                    type: 'success',
                    message: '已取消操作!',
                    center: true
                })
            });
        },
        // 下标序号
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 分页处理
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.querySeriesManageData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.querySeriesManageData();
        },
    },
}
</script>
<style scoped>
.demo-input-label {
    display: inline-block;
    width: 130px;
}

.jump {
    text-decoration: none;
    color: var(--el-table-font-color);
}
</style>