<template>
    <div class="history">
        <el-row style="padding: 25px">
            <bandManagePage v-if="showStatus == 'band'" @bandDetails="bandDetails"></bandManagePage>
            <seriesManagePage v-if="showStatus == 'series'" @cancelSeries="cancelSeries" :seriesDetailsData="seriesDetailsData"></seriesManagePage>
        </el-row>
    </div>
</template>
<script>
import bandManagePage from './bandManage.vue';
import seriesManagePage from './seriesManage.vue';
export default {
    components:{
        bandManagePage,
        seriesManagePage
    },
    data () {
        return {
            showStatus: 'band',
            // 品牌管理column数据
            seriesDetailsData: {}
        }
    },
    created() {

    },
    methods:{
        bandDetails(row){
            // console.log(row);
            this.showStatus = 'series';
            this.seriesDetailsData = row;
        },
        cancelSeries(){
            this.showStatus = 'band';
        }
    },

}
</script>
<style scoped>
.demo-input-label {
    display: inline-block;
    width: 130px;
}
</style>